<template>
  <section id="section-story" class="bg-green-super-light-green">
    <div class="nstra-container">
      <div v-scrollanimation="'up'">
        <img v-scrollanimation="'left'" class="dots" src="@/assets/images/dots-left-about.png" alt="dots" />
        <img v-scrollanimation="'right'" class="trapesium" src="@/assets/images/long-trapesium.png" alt="trapesium" />
        <b-row>
          <b-col cols="12" md="6">
            <h2 v-scrollanimation="'up'" class="story-title">Our Story</h2>
            <p v-scrollanimation="'left'" class="story-text">
              Founded in July 2011, we have steadily built Nostra Technology (Nostratech) to establish ourselves and lead in Indonesia’s application architecture and engineering market.
            </p>
            <p v-scrollanimation="'left'" class="story-text">
              We have since been recognized as a key player with a solid reputation for our full stack Oracle Fusion Middleware architects and engineers.
            </p>
          </b-col>
          <b-col cols="12" md="6">
            <img v-scrollanimation="'right'" class="story-img reguler-shadow reguler-border-radius" src="@/assets/images/about/img-our-story.jpeg" alt="our story image" />
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol
  },
};
</script>